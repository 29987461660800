$(function() {
    const $drawDates = $('#draw-dates').offset().top - 300

    const randomize = function() {
        const progressCircle = document.querySelector('.progress-circle-prog');
        const percent = $('.js-counter').attr('data-percent')
        progressCircle.style.strokeDasharray = (+percent * 8.5) + ' 999';
        const $progressText = $('.progress-text'); 
        $progressText.data('progress', +percent);
        const start = new Date().getTime();
    
        setTimeout(function() {
            const now = (new Date().getTime()) - start;
            const progress = now / 700;
            const result = Math.floor((+percent - 0) * progress + 0);
            $progressText.text(result);
            if (progress < 1) setTimeout(arguments.callee, 10);
        }, 10);
    }
    
    let flag = false 
    
    $(window).on('load scroll resize', function() {
        const scrollTop = $(this).scrollTop()
        if(scrollTop > $drawDates && !flag) {
            randomize()
            flag = true
        }
    })
})