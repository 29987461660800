$(function() {
    const $jsDrawDates = $('.js-draw-dates');
    const activeSlide = $jsDrawDates.attr('data-active'); 
    $jsDrawDates.slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        // variableWidth: true,
        initialSlide: +activeSlide,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    })

    $(window).on('load resize', function() {
        const $width = $(this).innerWidth();
        if($width > 940) return $jsDrawDates.slick('unslick')
        $jsDrawDates.slick('refresh')
    })
})