
// Global
    $(document).on('click', '.js-anchor', function (event) {
        event.preventDefault();
        $('body').css('overflow', 'visible');
        
        $('html, body').stop(true, true).delay(200).animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 100
        }, 500);   
    });


    $('.js-copy').on('click', function(e) {
        e.preventDefault()
        $('.sharing__copy-pop').fadeIn().delay(1000).fadeOut()
        const input = document.createElement('input')
        input.value = $(this).attr('href')
        document.body.appendChild(input);
        input.select()
        document.execCommand("copy")
        document.body.removeChild(input);
    })

// Global

import "%modules%/header/header";
import "%modules%/footer/footer";

import "%modules%/prizes/prizes";
import "%modules%/winners/winners";
import "%modules%/counter/counter";
import "%modules%/draw-dates/draw-dates";
import "%modules%/modals/modals";