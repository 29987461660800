$(function(){
    const $jsPrizesGains = $('.js-prizes__gains'),
          $jsPrizesAllBtn = $('.js-prizes__all-btn');

    const $jsPrizesGain = $('.js-prizes-gain');

    $jsPrizesAllBtn.on('click', function() {
        $jsPrizesGains.fadeOut()
        $(this).toggleClass("close")
        const idx = $jsPrizesAllBtn.index($(this))
        if($(this).hasClass('close')) {
            $jsPrizesGains.eq(idx).fadeIn()
            return $jsPrizesGain.slick('refresh')
        }
        $jsPrizesGains.eq(idx).fadeOut()
    })
    
    $(window).on('click scroll', function(e) {
        const target = $(e.target)
        const valid = !$jsPrizesGains.is(target) 
        && $jsPrizesGains.has(target).length === 0 &&
        !$jsPrizesAllBtn.is(target) 
        && $jsPrizesAllBtn.has(target).length === 0;

        if (valid) {
            $jsPrizesGains.fadeOut()
            $jsPrizesAllBtn.removeClass('close')
		}
    })

    

    $jsPrizesGain.slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1340,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    })

    $('.js-gain-prev').on('click', function() {
        $jsPrizesGain.slick('slickPrev')
    })
    $('.js-gain-next').on('click', function() {
        $jsPrizesGain.slick('slickNext')
    })
})