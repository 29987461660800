$(function(){
    $('.js-popup-link').magnificPopup({
		src: $(this).attr('data-mfp-src'),
		type: 'inline',
        callbacks: {
            close: function() {
                $('.js-form').removeClass('error-validation');
            }
        },
	})

    $('.js-modal-close').on('click', function() {
        $.magnificPopup.close();
    })

    $("[type='tel']").mask("+7 (999) 999-9999", {completed:function(){
        $form.removeClass('error-validation')
    }});

    const $form = $('.js-form'),
    $formInput = $('.js-form-input'),
    $formBtn = $('.js-form-btn');

    $form.on('submit', function(e) {
        e.preventDefault();
        const $postUrl = $(this).attr('data-action')
        const name = $(this).find('#name')
        const tel = $(this).find('#phone')

        if(name.val() === '' || tel.val() === '') {
            $(this).addClass('error-validation')
            if(name.length) name.focus()
            else tel.focus()
            return
        }

        $formBtn.attr('disabled', true)
        const nameval = name.val()
        const phone = $formInput.val().replace(/\D+/g, '').slice(1)

        postRequest($postUrl, { name: nameval, phone: phone })
    })



    const $formCode = $('.js-form-code');

    $('.js-input-code').on('input', function() {
        $(this).val($(this).val().slice(0, 6))
        $formCode.removeClass('error-validation')
    })

    $formCode.on('submit', function(e) {
        e.preventDefault();
        const $postUrl = $(this).attr('data-action')

        const code = $(this).find('#code')

        if(code.val() === '') {
            $(this).addClass('error-validation')
            return code.focus()
        }
        
        $formBtn.attr('disabled', true)

        postRequest($postUrl, { code: code.val() }, true)
    })


    function postRequest(url, data, stop) {
        $.post(url, data)
        .done(function(response){
            $formBtn.removeAttr('disabled')
            $formInput.val('')
            $.magnificPopup.close();
            if(stop) return
            $.magnificPopup.open({
                items: {
                    src: '#code-modal'
                },
                type: 'inline'
                }, 0);
        })
        .fail(function(err) {
            $formBtn.removeAttr('disabled')
        });
    }
})
