$(function() {
    const $jsWinnersTab = $('.js-winners__tab'),
          $jsWinnersUsers = $('.js-winners__users'),
          $jsWinnersFuture = $('.js-winners__future');
    let flag = false
    
    $jsWinnersTab.on('click', function() {
        $jsWinnersFuture.removeClass('show')
        $jsWinnersTab.removeClass('active');
        $jsWinnersUsers.removeClass('active');
        $(this).addClass('active');
        const idx = $jsWinnersTab.index($(this));
        $jsWinnersUsers.eq(idx).addClass('active');
        if($(this).hasClass('soon')) $jsWinnersFuture.addClass('show')
        $('.js-winners__tabs-select .winners__select-text').html($(this).clone())
        $('.winners__select-icon').removeClass('open')
        $(".winners__tabs").removeClass('show')
        flag = false
    })

    const clone = $('.js-winners__tab.current').clone()
    $('.js-winners__tabs-select .winners__select-text').html(clone)
    
    $('.js-winners__tabs-select').on('click', function() {
        $('.winners__select-icon').toggleClass('open')
        if(!flag) {
            $(".winners__tabs").fadeIn()
            return flag = true
        } 
        flag = false
    })

    $(window).on('click load', function(e) {
        const width = $(this).innerWidth()
 
        const target = $(e.target)
        const valid = !$('.js-winners__tabs-select').is(target) 
        && $('.js-winners__tabs-select').has(target).length === 0
        if (valid && width < 601) {
            $(".winners__tabs").fadeOut()
            $('.winners__select-icon').removeClass('open')
            flag = false
        }
    })
})